<template>
  <div class="image-carousel" :class="{ clickable: isClickable }">
    <Image
      :src="assets[0]"
      :alt="alt"
      @click="$emit('click')"
      v-if="assets.length === 1" />

    <Carousel v-else>
      <Slide v-for="asset in assets" :key="asset">
        <Image :src="asset" :alt="alt" @click="$emit('click')" />
      </Slide>

      <template #addons="{ slidesCount }">
        <Navigation v-if="slidesCount > 1" />
        <Pagination v-if="slidesCount > 1" />
      </template>
    </Carousel>
  </div>
</template>

<script>
  import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
  import "vue3-carousel/dist/carousel.css";
  import Image from "@/components/Images/Image.vue";

  export default {
    components: {
      Carousel,
      Slide,
      Pagination,
      Navigation,
      Image,
    },

    props: {
      assets: {
        type: Array,
      },
      alt: {
        type: String,
      },
      isClickable: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["click"],
  };
</script>

<style lang="scss" scoped>
  .image-carousel {
    width: 100%;
    padding-top: 56.25% !important;
    height: 0;
    position: relative;
    overflow: hidden;

    section.carousel {
      padding: 0 !important;
      margin: 0 !important;
    }

    &.clickable {
      cursor: pointer;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;

    @include flex($dir: row, $ai: center, $jc: center);

    > img,
    > source {
      width: 100% !important;
    }
  }
</style>
