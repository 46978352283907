<template>
  <div class="image" @click="$emit('click')">
    <link
      v-if="loadMode == 'preload'"
      rel="preload"
      fetchpriority="high"
      as="image"
      :href="getSrc"
      :type="getMimeType" />
    <img ref="img" :src="getDefaultSrc" :alt="alt" />
  </div>
</template>

<script>
  import { featureWebP } from "@/helpers/features/feature";

  export default {
    data() {
      return {};
    },

    props: {
      src: {
        type: String,
      },
      alt: {
        type: String,
      },
      loadMode: {
        // preload: Adds a link to start downloading right away, but doesn't update the image until after the page is interactive.
        // defer: Loads the image entirely after the page is interactive.
        // inline: Loads the image as part of the page load.
        type: String,
        default: "preload",
      },
      avif: {
        type: Boolean,
        default: false,
      },
      webP: {
        type: Boolean,
        default: true,
      },
    },

    emits: ["click"],

    computed: {
      showAvif() {
        return this.avif;
      },

      showWebP() {
        return this.webP && featureWebP().valueOf();
      },

      getDefaultSrc() {
        if (this.loadMode == "inline") {
          return this.getSrc;
        }

        return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAADUlEQVR42gECAP3/AOEA4wDi17x7CgAAAABJRU5ErkJggg==";
      },

      getSrc() {
        return `${this.src}.${this.getExtension}`;
      },

      getExtension() {
        return this.showAvif ? "avif" : this.showWebP ? "webp" : "jpg";
      },

      getMimeType() {
        return this.showAvif
          ? "image/avif"
          : this.showWebP
          ? "image/webp"
          : "image/jpg";
      },
    },

    methods: {
      loadImage() {
        if (this.loadMode != "inline") {
          let img = this.$refs.img;

          if (img != null) {
            img.className = this.getExtension;
            img.onerror = () => {
              img.src = this.getDefaultSrc;
            };
            img.src = this.getSrc;
          }
        }
      },
    },

    mounted() {
      this.$nextTick(function () {
        this.loadImage();
      });
    },
  };
</script>

<style lang="scss" scoped>
  .image {
    background-color: $col_disabled_gray;

    img {
      opacity: 0;
    }

    img.avif,
    img.webp,
    img.jpg {
      opacity: 1;
      animation: fade-in 0.2s ease-in-out 1;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
