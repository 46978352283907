export const feature = (feature) => {
  return window.Modernizr[feature];
};

export const featureWebP = () => {
  return feature("webp");
};

// export const featureAvif = async () => {
//   await isFormatSupported(
//     "avif",
//     "AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A="
//   );
// };

// const isFormatSupportedAsync = (format, dataUri) =>
//   new Promise((resolve, reject) => {
//     const image = new Image();

//     image.src = `data:image/${format};base64,${dataUri}`;

//     image.onload = () => {
//       resolve(true);
//     };

//     image.onerror = () => {
//       reject(format.toUpperCase() + " format not supported.");
//     };
//   });
